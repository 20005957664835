import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const addTemplate = async (payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tradeTemplates`;
    return await executePostData(url, payload, accessToken);
  }
  
  export const updateTradeTemplate = async (id: any, payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tradeTemplates/${id}`;
    return await executePutData(url, payload, accessToken);
  }
  
  export const deleteTemplate = async (id: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tradeTemplates/${id}`;
    return await executeDeleteData(url, accessToken);
  }
  
  export const getTemplates = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tradeTemplates`;
    return await executeGetData(url, accessToken);
  }
  
  export const getTemplatesByTradeId = async (id: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tradeTemplates/getByTradeId/id/${id}`;
    return await executeGetData(url, accessToken);
  }
  
  export const getTemplatesByTradeName = async (tradeOid: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/questionTemplate/getQuestionTemplate/tradeOid/${tradeOid}`;
    return await executeGetData(url, accessToken);
  }
  
  export const createQuestionTemplate = async (templateDTO: any,type:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/questionTemplate/addQuestionTemplate/questionType/${type}`;
    return await executePostData(url, templateDTO,accessToken);
  }
  
  
  export const getAllQuestionTemplate = async ( accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/questionTemplate/getAllQuestionTemplate`;
    return await executeGetData(url,accessToken);
  }
  export const getselectedTemplatesByVersion = async (tradeOid: any,version:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/questionTemplate/getQuestionTemplate/tradeOid/${tradeOid}/version/${version}`;
    return await executeGetData(url, accessToken);
  }
  
  