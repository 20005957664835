/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Template Add, Edit and Delete Implementation
* 1622           |  16/04/24   | shiyam kumar  |  Make as Componant Bom Setting 
* 1557           |  24/04/24   | shiyam kumar  |  Edit,Delete Issue Fix
* 1819           |  06/05/24   | Vanitha       |  All fields in new trade select trade field have a mandatory warning
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
* 2175           | 14-06-2024  |   Vanitha     | Remove search bar implementation
*/

import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from "@mui/material/Grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, useMediaQuery, useTheme } from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MenuItem from '@mui/material/MenuItem';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { deleteTrade, getTrades } from '../../services/TradeConfigurationServics';
import TradeForm from '../TradeForm';
import CustomDialog from '../../pages/Dialog';
import Header from '../Header';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Tradeconfiguration() {
    const [value, setValue] = React.useState(0);
    // const [selectedValue, setSelectedValue] = React.useState('All');
    const [open, setOpen] = React.useState(false);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [showQuestionBuilder, setShowQuestionBuilder] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const handleOpen = () => {

        setOpen(true)
    };
    const handlegantClose = () => setgantOpen(false);
    const [selectedTradeData, setSelectedTradeData] = React.useState<any>();
    const [selectedTemplate, setSelectedTemplate] = React.useState<any>();
    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDeletDialog, setOpenDeleteDialog] = React.useState(false);
    const [tradeId, setTradeId] = React.useState<any>();
    const [tradeTemplateId, setTradeTemplateId] = React.useState<any>();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [tradeType, setTradeType] = React.useState<any>('');
    const [type, setType] = React.useState<any>('');
    const [tradeName, setTradeName] = React.useState<any>('');

    const [data, setData] = React.useState<any>({
        name: undefined,
        manufacturer: undefined,
        materialType: undefined,
        description: undefined,
        code: undefined
    });
    const [templateList, setTemplateList] = React.useState<any>([]);

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenDeleteDialog(false);
    };

    const handleShowQuestionTemplate = () => {
        setShowQuestionBuilder(true);
    }
    //to handle asset questions 
    const handleAssetQuestionsClick = () => {
        // Add any specific logic you want to execute when the "Asset Questions" tab is clicked
        // For example, you can pass the asset prop to the Questions component
        setType("asset")
    };


    const tabhandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange = (event: any) => {
        // getTradeList();
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const showGenericTemplate = () => {
        setShowTemplate(true);
    }

    const editTemplate = (template: any) => {
        setSelectedTemplate(template);
        setShowTemplate(true);
    }

    const [tasks, setTasks] = React.useState([]);
    const [gantopen, setgantOpen] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();


    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        setTradeData(result.data);
        ////console.log(result.data)

        result?.data?.map((data) => {
            tradenames.push(data?.name)
        })
        setTradeNames(Array.from(new Set(tradenames)));
 
    }

    const handleDialogOpen = (id: any) => {
        setTradeId(id);
        setOpenDialog(true);
    }

    const onDeleteTrade = async () => {
        setOpenDialog(false);
        if (tradeId !== null) {
            let result = await deleteTrade(tradeId, accessToken);
            ////console.log(result);
            if (result.status === 200 || result.status === 201 || result.status === 204) {
                var res = await getTrades(accessToken);
                setTradeData(res.data);
                setSuccess(true);
            }
            else {
            }
        }
        else {
        }
    }


    const closeTradeForm = async () => {
        setOpen(false);
        setSelectedTradeData(undefined);
        let result = await getTrades(accessToken);
        setTradeData(result.data);
    }

    const handleEditTrade = (row: any) => {
        setSelectedTradeData(row);
        setTradeType(row.type)
        setOpen(true);
    }

    const handleDelete = (id: any) => {
        setOpenDeleteDialog(true);
        setTradeTemplateId(id);
    }


    React.useEffect(() => {
        getTradeList();
        var newArr: any = ["0"];
        newArr = newArr.filter(item => item !== "0");
    }, []);

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
    };

    const handleEditTemplate = (templateName: any,tradeType:any) => {
        ////console.log(templateName,tradeData)
        setTradeName(templateName);
        setTradeType(tradeType)
        setShowQuestionBuilder(true);
    }
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            
                <Grid xs={12} md={12} >
                <Box mb={2}
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            > 
                <Header title="Trade List" subtitle="" />
            </Box>
            <><Grid
                                container
                                sx={{ p: 0 }}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                <Grid item xs={12} md={8}>
                                </Grid>
                            </Grid>

                                <TableContainer component={Paper}>
                                    <Table size="small" >
                                        <TableHead>
                                            <TableRow className="tableHead">
                                                <TableCell align="left">Trade</TableCell>
                                                <TableCell align="left">Type</TableCell>
                                                <TableCell align="left">Description</TableCell>
                                                <TableCell align="left">Code</TableCell>

                                                <TableCell align="left">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tradeData && tradeData.length > 0 ? (
                                                tradeData.map((row) => (
                                                    <TableRow
                                                        key={row.Id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell size="small" component="th" scope="trade" align="left">
                                                            {row?.name}
                                                        </TableCell>
                                                        <TableCell size="small" align="left">{row?.type}</TableCell>
                                                        <TableCell  size="small" align="left">{row?.description}</TableCell>
                                                        <TableCell  size="small" align="left">{row?.code}</TableCell>

    
                                                        <TableCell size="small" align="left">
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'left',
                                                                    '& > *': {
                                                                        m: 1,
                                                                    },
                                                                }}
                                                            >
                                                                <Stack direction="row" spacing={1}>
                                                                    <Tooltip title="Edit" arrow>
                                                                        <IconButton className='prmaryiconButton' aria-label="edit" onClick={() => handleEditTrade(row)}>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    {/* <Tooltip title="Delete" arrow>
                                                                        <IconButton className='erroriconButton' aria-label="delete" onClick={() => handleDialogOpen(row?.id)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Tooltip> */}
                                                                </Stack>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={4} rowSpan={5} align="center">
                                                        <Typography variant="body1">No data available</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer> </>
                  
                    <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                    >
                        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                            <DialogContent >
                                <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                                <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                    Are you sure?
                                </Typography>
                                <Typography textAlign={'center'}>
                                    You won't be able to revert this!
                                </Typography>
                            </DialogContent>
                        </Box>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                            <Button onClick={() => onDeleteTrade()} autoFocus className="primaryTonalButton">
                                Yes, Delete it!
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <Dialog
                        open={openDeletDialog}
                        onClose={handleDialogClose}
                    >
                        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                            <DialogContent >
                                <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                                <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                    Are you sure?
                                </Typography>
                                <Typography textAlign={'center'}>
                                    You won't be able to revert this!
                                </Typography>
                            </DialogContent>
                        </Box>
                    
                    </Dialog>
                  {open && <TradeForm open={open}  function={closeTradeForm} row={selectedTradeData}/>}
                    {/* <CustomTabPanel value={value} index={2}>
                        <Box>
                            <Questions questionType={type} tradeTypeName={tradeType} />
                        </Box>
                    </CustomTabPanel> */}
                    {/* <ConfirmationDialog  open={openDialog}  onClose={() => setOpenDialog(false)}/> */}
                

                </Grid>
            </Grid>

            <CustomDialog
                open={success}
                onClose={() => setSuccess(false)}
                success={success}
                error={error}
                Content={success ? "Trade has been deleted successfully" : "Error could not deleted "}
            />

        </Box>
    );
}
