import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardActionArea, CardContent, CardHeader, Divider, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import { BasicDefinitions, CodeList, CodeListRef, ConditionDef, FormDef, ItemDef, ItemGroupDef } from '../../../interface/SdmInterfaces';
import '../QuestionBuilder.scss';
import TextComponent from './TextComponent';
import SingleChoiceComponent from './SingleChoiceComponent';
import MultiChoiceComponent from './MultiChoiceComponent';
import IntegerComponent from './IntegerComponent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'; import BooleanComponent from './BooleanComponent';
import DateTimeComponent from './DateTimeComponent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConditionalDef from './ConditionDef';
import MultiLineText from './MultiLineText';
import CircularProgress from '@mui/material/CircularProgress'; // Import the loading spinner component
import Loading from '../../../auth/Loading.component';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import DateComponent from './DateComponent';
import Category from '../../../data/category.json';
import Questions from './Questions';
import { getCategoryMaster } from '../../../services/CategoryService';
import ItemGroupConditionDef from './ItemGroupCondition';
interface Props {
  questions: ItemDef[]; // Replace with your specific type
  setQuestions: React.Dispatch<React.SetStateAction<any[]>>; // Replace with your specific type
  codeLists: CodeList[];
  setCodeLists: React.Dispatch<React.SetStateAction<CodeList[]>>;
  itemGroupDefs: ItemGroupDef[];
  conditionalDef: ConditionDef[];
  setConditionalDef: React.Dispatch<React.SetStateAction<ConditionDef[]>>;
  setItemGroupDefs: React.Dispatch<React.SetStateAction<ItemGroupDef[]>>;
  formDef: FormDef;
  formDefs: FormDef[];
  setFormDef: React.Dispatch<React.SetStateAction<FormDef[]>>;
  basicDefinitionData: BasicDefinitions;
  isloading: boolean;
  setConditionOid: any;
  setItemOId: any;
  setCodeOid: any;
  incompleteQuestions: any;
  setIncompleteQuestions: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTab: any;
}


const QuestionCard: React.FC<Props> = ({ itemGroupDefs, setItemGroupDefs, questions, setQuestions, codeLists, setCodeLists, conditionalDef, setConditionalDef, formDef, formDefs, setFormDef, selectedTab, basicDefinitionData, isloading, setConditionOid, setItemOId, setCodeOid, incompleteQuestions, setIncompleteQuestions }) => {
  // const [questions, setQuestions] = useState<ItemDef[]>([]);
  // const [codeLists, setCodeLists] = useState<CodeList[]>([]);
  //console.log(itemGroupDefs, questions, codeLists, conditionalDef)
  // Initialize with a default question card if questions.length is 0

  const [selectedCategory, setSelectedCategory] = useState('');
  const [category, setCategory] = useState<any>(); // To handle conditional ref


  const [isRequired, setIsRequired] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorIt, setAnchorIt] = useState(null);

  const [conditionalRef, setConditionalRef] = useState<any>(); // To handle conditional ref
  const [conditionalRefItemOId, setConditionalRefItemOId] = useState<any>(); // To handle conditional ref
  const conditionalRefItemGroupOId = useRef<any>(); // To handle conditional ref

  const [conditionalDefItemGroupOId, setConditionalDefItemGroupOId] = useState<any>(''); // To handle conditional ref

  const [itemDefs, setItemDefs] = useState<ItemDef[]>([]);
  const [selectedQuestionOID, setSelectedQuestionOID] = useState<string>('');
  const [selectedItemGroupCard, setSelectedGroupCard] = useState<string>('');
  const [keyItemGroups, setKeyItemGroups] = useState<ItemGroupDef[]>([]);

  const [isRequiredMap, setIsRequiredMap] = useState({});
  // const [incompleteQuestions, setIncompleteQuestions] = useState<string[]>([]);
  const loading = useRef<boolean>(isloading); // Use useRef to hold loading state
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [role, setRole] = React.useState<any>([] as any[]);
  const [oldConditionalDef, setOldConditionalDef] = useState<ConditionDef[]>();
  const [oldItemDef, setOldItemDef] = useState<ItemDef[]>();
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);

  const previousSelectedTabRef = useRef('');

  //operand  changes 
  const handleFetchData = async () => {
    if (selectedTab !== 'asset' && selectedTab !== previousSelectedTabRef.current) {
      loading.current = true;
      try {
        // Simulate data fetching
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
        await data(); // Fetch data
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        loading.current = false;
        setIsInitialLoad(false); 
      }
    }
    // Update ref to the latest selectedTab
    previousSelectedTabRef.current = selectedTab;
  };

  React.useEffect(() => {
    handleFetchData();
  }, [selectedTab, itemGroupDefs, conditionalDef]);

  React.useEffect(() => {
    //console.logg(questions,isloading ,loading)
    //   //console.logg(itemGroupDefs,questions)
    // if (questions) {
    //   setLoading(false);
    // }
    //   //console.logg(isloading)
    //  loading.current=isloading;
    // if (keyItemGroups) {
    //   setLoading(false);
    // }
    //console.logg(isloading)
    if (selectedTab !== 'asset' && selectedTab === previousSelectedTabRef.current) {
      // getUserSession(accessToken).then(res => {
      //   setRole(res.role.name);

      // });
      ////////  //console.logg(conditionalDef)
      data();
      fetchData();
      if (itemGroupDefs.length === 0) {

      }
      else {
        itemGroupDefs.forEach(itemGroup => {
          if (itemGroup.ItemRef.length === 0) {
            handleAddQuestion(itemGroup.OID);
          }
        });
      }
    }

  }, [itemGroupDefs, conditionalDef]);// Empty dependency array ensures it runs only once on component mount

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  const fetchData = async () => {
    const res = await getCategoryMaster(accessToken);
    //console.log(res)
    setCategory(res.data)
    //   const response = await getAllTenants(searchParams,accessToken, page, rowsPerPage);
    //   //console.log(response);
    //   setRows(response.data);
  };

  useEffect(() => {
    // Add logic here that runs when formDefs changes
    if (formDefs) {
      //console.log("formDefs updated:", formDefs);
      // Perform any other logic you need with formDefs
    }
  }, [formDefs]); // Dependency array

  //codelist ref generation
  const generateCodeListRef = (): CodeListRef => {
    // ////  //console.logg("Current codeLists:", codeLists);

    // Filter out any codeLists with OIDs like 'CL.NaN'
    const validCodeLists = codeLists.filter(codeList => {
      const num = parseInt(codeList.OID?.split('.')[1]);
      return !isNaN(num);
    });

    let newNumber: number;

    // Check if validCodeLists is empty or if the last OID is correctly formatted
    if (validCodeLists.length > 0) {
      // Sort the validCodeLists based on the numerical part of the OID
      validCodeLists.sort((a, b) => {
        const numA = parseInt(a.OID.split('.')[1], 10);
        const numB = parseInt(b.OID.split('.')[1], 10);
        return numB - numA; // Sort in descending order
      });

      // After sorting, the first element will have the highest OID
      const highestCodeListOID = validCodeLists[0].OID;
      const parts = highestCodeListOID.split('.');

      if (parts.length === 2 && !isNaN(parseInt(parts[1], 10))) {
        const lastNumber = parseInt(parts[1], 10);
        newNumber = lastNumber + 1;
      } else {
        // Fallback if OID format is not as expected
        newNumber = 1;
      }
    } else {
      // Handle case where validCodeLists is empty
      newNumber = 1;
    }
    const newCodeListOID = `CL.${newNumber}`;

    // Create the new CodeList object
    const newCodeList: CodeList = {
      CodeListItem: [],
      OID: newCodeListOID,
      Name: newCodeListOID,
      DataType: 'text' // Adjust data type as needed
    };

    // ////  //console.logg("New CodeListOID:", newCodeListOID);

    // Update the state with the new CodeList
    setCodeLists([...validCodeLists, newCodeList]);

    // Return the new CodeListOID reference
    return { CodeListOID: newCodeListOID };
  };
  const data = async () => {
    try {
      // if(itemGroupDefs === undefined){
      //   loading.current = true; // Ensure loading is stopped in both success and error cases

      // }
      // Fetch relevant form definition
      // //console.log("hiii",itemGroupDefs)
      const relevantRes: any = formDefs.find((res) => res.OID === formDef.OID);

      if (!relevantRes) {
        // If no relevant form definition, clear the item groups
        setKeyItemGroups([]);
        return;
      }

      // Map through ItemGroupRef and build the ItemGroups array
      const ItemGroups: ItemGroupDef[] = relevantRes.ItemGroupRef.map((item: any) => {
        const itemGroupData = itemGroupDefs.find(
          (itemGroupData: any) => item.ItemGroupOID === itemGroupData.OID
        );

        if (itemGroupData) {
          // Return valid ItemGroupDef with description
          return {
            ...itemGroupData,
            Description: {
              TranslatedText: {
                lang: 'en',
                text: itemGroupData.Description?.TranslatedText?.

                  text,
              },
            },
          };
        }
        return undefined; // Explicitly return undefined for missing items
      }).filter((itemGroupData): itemGroupData is ItemGroupDef => itemGroupData !== undefined); // Filter out undefined entries

      // Ensure state update only if ItemGroups is valid and non-empty
      if (ItemGroups.length > 0) {
        setKeyItemGroups(ItemGroups);
      } else {
        setKeyItemGroups([]); // Clear if no valid item group
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      loading.current = false; // Ensure loading is stopped in both success and error cases
    }
  };
  //for generating conditionalDefOid
  const generateCondtionDefRef = (itemGroupId: string, itemOid: string, type: string) => {
    //console.log(itemGroupId)
    // Filter out conditional definitions with valid numeric parts in OID (like 'C.NaN' is invalid)
    const validConditionalDef = conditionalDef.filter(def => {
      const num = parseInt(def.OID.split('.')[1]);
      return !isNaN(num);
    });

    // Default OID starts with 'C.1'
    let newConditionalOID = 'C.1';

    // If there are valid conditional definitions, find the max number after 'C.' and increment it
    if (validConditionalDef.length > 0) {
      const maxOidNumber = validConditionalDef
        .map(def => parseInt(def.OID.split('.')[1])) // Extract the number after 'C.'
        .filter(num => !isNaN(num))                  // Ensure valid numbers
        .reduce((max, num) => Math.max(max, num), 0); // Get the maximum number

      newConditionalOID = `C.${maxOidNumber + 1}`;   // Increment the maximum number
    }

    // Create a new conditional definition with the new OID
    const newConditionalDef = {
      Description: {
        TranslatedText: {
          lang: "en",
          text: newConditionalOID
        }
      },
      FormalExpression: {
        Context: "OpenEDC",
        text: ""
      },
      OID: newConditionalOID,
      Name: newConditionalOID
    };
    if (type === 'ItemGroup') {
      const existingFormIndex = formDefs.findIndex(form => form.OID === formDef.OID);
      //console.log(existingFormIndex)
      if (existingFormIndex !== -1) {
        const existingFormGroup = { ...formDefs[existingFormIndex] };

        //console.log("newConditionalOID", newConditionalOID)
        const conditionItemGroupData = existingFormGroup.ItemGroupRef.find(
          (fi) => fi.ItemGroupOID === itemGroupId
        );
        //console.log(conditionItemGroupData) // Check if the given ItemGroupOID exists in the itemGroupDefs
        if (conditionItemGroupData) {
          conditionItemGroupData.CollectionExceptionConditionOID = newConditionalOID
        }
        const updatedFormDefs = [
          ...formDefs.slice(0, existingFormIndex),
          existingFormGroup,
          ...formDefs.slice(existingFormIndex + 1)
        ];
        //console.log("2546436",updatedFormDefs)
        setFormDef(updatedFormDefs);
      }
    }
    else {
      const existingItemGroupIndex = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupId);
      if (existingItemGroupIndex !== -1) {
        // If the ItemGroup exists, find the related ItemRef
        const existingItemGroup = { ...itemGroupDefs[existingItemGroupIndex] };
        const existingItemRef = existingItemGroup.ItemRef.find(itemRef => itemRef.ItemOID === itemOid);

        if (existingItemRef) {
          // Add CollectionExceptionConditionOID to the found ItemRef
          existingItemRef.CollectionExceptionConditionOID = newConditionalOID;

          // Update itemGroupDefs with the modified item group
          const updatedItemGroupDefs = [
            ...itemGroupDefs.slice(0, existingItemGroupIndex),
            existingItemGroup,
            ...itemGroupDefs.slice(existingItemGroupIndex + 1)
          ];
          setConditionOid(prev => {
            const newSet = new Set(prev);
            newSet.add(existingItemRef?.CollectionExceptionConditionOID);
            //console.log('newSet', newSet)
            return newSet;
          });
          // Update the state with the new array
          setItemGroupDefs(updatedItemGroupDefs);
        } else {
          //console.log('ItemRef not found');
        }
      } else {
        //console.log('ItemGroupOID not found');
      }
    }


    // Update the conditional definitions state
    setConditionalDef([...validConditionalDef, newConditionalDef]);

    // Return the new conditional definition OID
    return { conditionalDefOId: newConditionalOID };
  };



  const handleDuplicateQuestion = (itemGroupOid: string, itemOid: string) => {
    const existingItemGroupIndex = itemGroupDefs.find(itemGrpOid => itemGrpOid.OID === itemGroupOid);
    const itemRef = existingItemGroupIndex?.ItemRef.findIndex(itemRef => itemRef.ItemOID === itemOid);
    const itemDefIndex = questions.findIndex(itemDef => itemDef.OID === itemOid);
    // ////  //console.logg(itemDefIndex);
    const existingItemDef = { ...questions[itemDefIndex] };
    if (existingItemDef.DataType === 'multiselect' || existingItemDef.DataType === 'multiSelectComboBox') {
      // ////  //console.logg(existingItemDef.Question.TranslatedText.text)
      // ////  //console.logg(existingItemDef.CodeListRef?.CodeListOID)
      // if(existingItemDef.CodeListRef?.CodeListOID){
      //   codeLists.find(codeList=>codeList.CodeListItem.)
      // }
    }
    // Find the last OID that starts with 'I.' and extract the number part
    const lastItemIndex = questions.reduce((maxIndex, question) => {
      if (question.OID && question.OID.startsWith('I.')) {
        const index = parseInt(question.OID.split('.')[1]);
        return index > maxIndex ? index : maxIndex;
      }
      return maxIndex;
    }, 0);

    // Determine the next OID
    let nextIndex = lastItemIndex < 1 ? 1 : lastItemIndex + 1;
    ////////  //console.logg(questions)
    // Check if nextIndex already exists in questions, increment if it does
    while (questions.some(question => question.OID === `I.${nextIndex}`)) {
      nextIndex++;
    }

    // Generate a new question item with the updated OID
    const newItem: ItemDef = {
      Question: {
        TranslatedText: {
          lang: 'en',
          text: existingItemDef.Question.TranslatedText.text, // Default to empty string
        },
      },
      OID: `I.${nextIndex}`,
      Name: `I.${nextIndex}`,
      DataType: existingItemDef.DataType, // Default to 'integer'
    };

    // Update questions state
    setQuestions((prevQuestions) => [...prevQuestions, newItem]);
    // setNewEventData((prevQuestions) => [...prevQuestions, newItem]);

    // Check if ItemGroupOID already exists in itemGroupDefs
    const existingItemGroup = itemGroupDefs.find(itemGroup => itemGroup.OID === itemGroupOid);

    if (existingItemGroup) {
      // If ItemGroupOID exists, update it with newItem.OID if it doesn't already have it
      if (!existingItemGroup.ItemRef.some(itemRef => itemRef.ItemOID === newItem.OID)) {
        const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
          if (itemGroup.OID === itemGroupOid) {
            return {
              ...itemGroup,
              ItemRef: [...itemGroup.ItemRef, { ItemOID: newItem.OID, Mandatory: 'No' }],
            };
          }
          return itemGroup;
        });
        setItemGroupDefs(updatedItemGroupDefs);
      }
    } else {
      // If ItemGroupOID doesn't exist, create a new itemGroup with newItem.OID
      const newGroup: ItemGroupDef = {
        OID: itemGroupOid,
        ItemRef: [{ ItemOID: newItem.OID, Mandatory: 'No' }],
        Description: {
          TranslatedText: {
            lang: "en",
            text: ""
          }
        },
        Name: '',
        Repeating: ''
      };
      setItemGroupDefs([...itemGroupDefs, newGroup]);
    }


  }
  const handleQuestionClick = (questionOID: string) => {
    setSelectedQuestionOID(questionOID);
  };

  const handleItemGroupCardClick = (itemGroupOID: string) => {
    setSelectedGroupCard(itemGroupOID);
  };

  //to handle itemOid is required or not 
  const handleToggleRequired = (itemGroupOID, itemDefOID) => {
    ////////  //console.logg(itemGroupOID)
    // Toggle the state for the specific item
    setIsRequiredMap(prevState => (
      {
        ...prevState,
        [itemGroupOID]: {
          ...prevState[itemGroupOID],
          [itemDefOID]: !prevState[itemGroupOID]?.[itemDefOID],
        },
      }));
    // Check if ItemGroupOID already exists in itemGroupDefs
    const existingItemGroupIndex = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupOID);
    if (existingItemGroupIndex !== -1) {
      // If ItemGroupOID exists, find the ItemRef
      const existingItemGroup = { ...itemGroupDefs[existingItemGroupIndex] };
      const existingItemRef = existingItemGroup.ItemRef.find(itemRef => itemRef.ItemOID === itemDefOID);
      if (existingItemRef) {
        // Add CollectionExceptionConditionOID to the existing ItemRef
        const isCurrentlyRequired = isRequiredMap[itemGroupOID]?.[itemDefOID];
        existingItemRef.Mandatory = isCurrentlyRequired ? "No" : "Yes";
        // Create a new array with the updated item group
        const updatedItemGroupDefs = [
          ...itemGroupDefs.slice(0, existingItemGroupIndex),
          existingItemGroup,
          ...itemGroupDefs.slice(existingItemGroupIndex + 1)
        ];
        // Update the state with the new array
        ////////  //console.logg(updatedItemGroupDefs)
        setItemGroupDefs(updatedItemGroupDefs);
        // Track changes in the changedItemOIDs set
        setItemOId(prev => {
          const newSet = new Set(prev);
          newSet.add(itemDefOID);
          return newSet;
        });
      }
    }
  };

  // const handleSaveAll = () => {
  //   const itemDefs = questions.map(question => {
  //     const codeList = codeLists.find(cl => cl.OID === question.CodeListRef?.CodeListOID);
  //     return {
  //       ...question,
  //       // Attach the corresponding CodeList if needed
  //     };
  //   });

  //   const dataToSave = {
  //     itemDef: itemDefs,
  //     codeList: codeLists,
  //     itemGroupDef: itemGroupDefs
  //   };

  //   ////////  //console.logg('Data to Save:', dataToSave, dataToSave.itemDef, dataToSave.itemGroupDef, dataToSave.codeList);
  //   handlePropsChange(dataToSave.itemDef, dataToSave.itemGroupDef, dataToSave.codeList);
  //   // Example: Perform actual saving logic here (e.g., sending to backend)
  //   // saveData(dataToSave);
  // };

  const handleAddQuestion = (ItemGroupOID: any) => {
    ////  //console.logg(questions.length > 0)
    // Check for incomplete questions
    ////   //console.logg(keyItemGroups)
    if (questions.length > 1) {
      const itemOIDsInKeyItemGroups = keyItemGroups.flatMap(group =>
        group.ItemRef.map(ref => ref.ItemOID)
      );
      // Filter questions to find those that are incomplete
      const incompleteQuestionOIDs = questions
        .filter(question =>
          itemOIDsInKeyItemGroups.includes(question.OID) &&
          !question.Question.TranslatedText.text
        )
        .map(question => question.OID);
      if (incompleteQuestionOIDs.length > 0) {
        // Set the validation state for incomplete questions
        setIncompleteQuestions(incompleteQuestionOIDs);
        return; // Do not proceed with adding a new question
      }
      // Clear incomplete questions state if all questions are complete
      setIncompleteQuestions([]);
    }
    // Filter out any questions with OIDs like 'I.NaN'
    const validQuestions = questions.filter(question => {
      const num = parseInt(question.OID?.split('.')[1]);
      return !isNaN(num);
    });

    // Find the last OID that starts with 'I.' and extract the number part
    const lastItemIndex = validQuestions.reduce((maxIndex, question) => {
      if (question.OID && question.OID.startsWith('I.')) {
        const index = parseInt(question.OID.split('.')[1]);
        return index > maxIndex ? index : maxIndex;
      }
      return maxIndex;
    }, 0);

    // Determine the next OID
    let nextIndex = lastItemIndex < 1 ? 1 : lastItemIndex + 1;

    // Check if nextIndex already exists in questions, increment if it does
    while (validQuestions.some(question => question.OID === `I.${nextIndex}`)) {
      nextIndex++;
    }

    // Generate a new question item with the updated OID
    const newItem: ItemDef = {
      Question: {
        TranslatedText: {
          lang: 'en',
          text: '', // Default to empty string
        },
      },
      OID: `I.${nextIndex}`,
      Name: `I.${nextIndex}`,
      DataType: 'integer', // Default to 'integer'
    };

    // Update questions state
    setQuestions((prevQuestions) => [...validQuestions, newItem]);

    // Check if ItemGroupOID already exists in itemGroupDefs
    const existingItemGroup = itemGroupDefs.find(itemGroup => itemGroup.OID === ItemGroupOID);

    if (existingItemGroup) {
      // If ItemGroupOID exists, update it with newItem.OID if it doesn't already have it
      if (!existingItemGroup.ItemRef.some(itemRef => itemRef.ItemOID === newItem.OID)) {
        const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
          if (itemGroup.OID === ItemGroupOID) {
            return {
              ...itemGroup,
              ItemRef: [...itemGroup.ItemRef, { ItemOID: newItem.OID, Mandatory: 'No' }],
            };
          }
          return itemGroup;
        });
        setItemGroupDefs(updatedItemGroupDefs);
      }
    } else {
      // If ItemGroupOID doesn't exist, create a new itemGroup with newItem.OID
      const newGroup: ItemGroupDef = {
        OID: ItemGroupOID,
        ItemRef: [{ ItemOID: newItem.OID, Mandatory: 'No' }],
        Description: {
          TranslatedText: {
            lang: "en",
            text: ""
          }
        },
        Name: '',
        Repeating: ''
      };
      setItemGroupDefs([...itemGroupDefs, newGroup]);
    }

    // ////  //console.logg(questions, itemGroupDefs);
  };

  //to show  no of operands

  const handleQuestionChange = (itemOID: string, field: string, value: string) => {
    // Find the index of the question to update
    const index = questions.findIndex(question => question.OID === itemOID);
    //console.log(value)
    if (index === -1) {
      // Question not found; handle this case if necessary
      console.error(`Question with OID ${itemOID} not found.`);
      return;
    }

    // Update the questions state
    setQuestions(prevQuestions => {
      const updatedQuestion = {
        ...prevQuestions[index],
        Question: {
          ...prevQuestions[index].Question,
          TranslatedText: {
            ...prevQuestions[index].Question.TranslatedText,
            text: value, // Update the text field with the new value
          },
        },
      };

      return [
        ...prevQuestions.slice(0, index),
        updatedQuestion,
        ...prevQuestions.slice(index + 1),
      ];
    });

    // Track changes in the changedItemOIDs set
    setItemOId(prev => {
      const newSet = new Set(prev);
      newSet.add(itemOID);
      return newSet;
    });

    // Handle incomplete questions
    if (value.trim() !== '') {
      // Remove OID from incompleteQuestions if the value is not empty
      setIncompleteQuestions(prev => prev.filter(id => id !== itemOID));
    } else {
      // Add OID to incompleteQuestions if the value is empty
      setIncompleteQuestions(prev => {
        return prev.includes(itemOID) ? prev : [...prev, itemOID];
      });
    }
  };



  //if already created question changeds  then this function will call
  const handleMeasurementChange = (itemOID: string, value: string) => {
    //   //console.logg('New Measurement OID:', value);
    const updatedQuestions = questions.map((question) => {
      if (question.OID === itemOID) {
        //   //console.logg('Updating question with OID:', itemOID, 'Current question:', question);
        return {
          ...question,
          MeasurementOid: value // Directly update MeasurementOid in the ItemDef object
        };
      }

      return question;
    });
    // Track changes in the changedItemOIDs set
    setItemOId(prev => {
      const newSet = new Set(prev);
      newSet.add(itemOID);
      return newSet;
    });
    //   //console.logg('Updated Questions:', updatedQuestions);
    setQuestions(updatedQuestions);
  };




  //handleQuestions selecting for conditional Rendering
  // createConditionalDefFormalExpression
  const handleEmptyItemGroups = () => {
    // ////  //console.logg("formDefs");
    const formIndex = formDefs.findIndex((form) => form.OID === formDef.OID);
    if (formIndex === -1) {
      // console.error(`FormDef with OID not found.`);
      return;
    }
    // Extract the numerical parts of all ItemGroupOIDs and find the largest one
    let maxItemGroupOID: string | null = null;
    let maxNumber = -Infinity;
    formDefs.forEach((item) => {
      item.ItemGroupRef.forEach((group) => {
        const number = parseInt(group.ItemGroupOID.replace('IG.', ''), 10);
        if (!isNaN(number) && number > maxNumber) {
          maxNumber = number;
          maxItemGroupOID = group.ItemGroupOID;
        }
      });
    });
    let newGroupOID: string;
    const formDefDetail = { ...formDefs[formIndex] }; // Create a copy to avoid direct mutation
    if (maxNumber === -Infinity) {
      // No valid item group OID found
      newGroupOID = "IG.1";
      formDefDetail.ItemGroupRef = [
        {
          ItemGroupOID: newGroupOID,
          Mandatory: "No"
        }
      ];
    } else {
      const lastGroupNumber = maxNumber;
      newGroupOID = `IG.${lastGroupNumber + 1}`;
      formDefDetail.ItemGroupRef = [
        ...formDefDetail.ItemGroupRef,
        {
          ItemGroupOID: newGroupOID,
          Mandatory: "No"
        }
      ];
    }

    ////  //console.logg("newGroupOID", newGroupOID);
    // Create a new item group definition
    const newGroup: ItemGroupDef = {
      OID: newGroupOID,
      ItemRef: [],
      Description: {
        TranslatedText: {
          lang: "en",
          text: ""
        }
      },
      Name: newGroupOID,
      Repeating: "No"
    };

    // Add the new item group definition to itemGroupDefs
    setItemGroupDefs((prevItemGroupDefs) => {
      // Filter out any item groups with empty OID before adding the new group
      const filteredItemGroupDefs = prevItemGroupDefs.filter(group => group.OID !== "");
      return [...filteredItemGroupDefs, newGroup];
    });
    // Filter out any ItemGroupRef with empty ItemGroupOID
    formDefDetail.ItemGroupRef = formDefDetail.ItemGroupRef.filter(group => group.ItemGroupOID !== "");
    // Update the formDefs state with the updated formDef
    const updatedFormDefs = [...formDefs];
    updatedFormDefs[formIndex] = formDefDetail;
    setFormDef(updatedFormDefs);
    data();
    ////  //console.logg("formDefDetail", updatedFormDefs);
  };


  //to delete itemGroup
  const handleDeleteItemGroup = (itemGroupOid: string) => {
    // Find the item group with the specified OID
    const itemGroupToDelete = itemGroupDefs.find(itemGroup => itemGroup.OID === itemGroupOid);
    // If the item group exists, remove related questions
    if (itemGroupToDelete) {
      // Create a new array of questions excluding those referenced by the item group
      const updatedQuestions = questions.filter(
        (itemDef) => !itemGroupToDelete.ItemRef.some((itemRef) => itemRef.ItemOID === itemDef.OID)
      );
      // Update the state with the new array of questions
      setQuestions(updatedQuestions);
    }
    // Create a new array with updated ItemGroupRef where the specific ItemGroupOID is completely removed
    const updatedFormDefs = formDefs.map((formData) => {
      // Filter out the specific ItemGroupOID from the current formData's ItemGroupRef
      const updatedItemGroupRef = formData.ItemGroupRef.filter((itemGroupDef) => itemGroupDef.ItemGroupOID !== itemGroupOid);
      // Return the updated formData with the modified ItemGroupRef
      return {
        ...formData,
        ItemGroupRef: updatedItemGroupRef,
      };
    });
    // Update formDef state with the new formDefs array
    setFormDef(updatedFormDefs);
    // Create a new array excluding the item group with the specified OID
    const updatedItemGroupDefs = itemGroupDefs.filter(
      (itemGroup) => itemGroup.OID !== itemGroupOid
    );
    // Update the state with the new array of item groups
    setItemGroupDefs(updatedItemGroupDefs);
  };

  //to render component based on data tupe
  const handleDataTypeChange = (itemOID: string, event: React.ChangeEvent<{ value: unknown }>) => {
    // Find the index of the question in the questions array
    const index = questions.findIndex(q => q.OID === itemOID);
    // const newIndex=newEventData.findIndex(q => q.OID === itemOID)
    if (index === -1) {
      return; // Question not found, handle accordingly
    }
    const newQuestions = [...questions];
    const dataType = event.target.value as string;
    let codeList: CodeListRef | null = null;

    // Set CodeListRef for specific data types and remove MeasurementOid if necessary
    if (dataType === 'singleselect' || dataType === 'multiselect' || dataType === 'combobox' || dataType === 'multiSelectComboBox') {
      codeList = generateCodeListRef();
      //  //console.logg(newQuestions)
      delete newQuestions[index].MeasurementOid;
    }
    newQuestions[index] = {
      ...newQuestions[index],
      DataType: dataType,
      CodeListRef: codeList || undefined,
    };


    // Track changes in the changedItemOIDs set
    setItemOId(prev => {
      const newSet = new Set(prev);
      newSet.add(itemOID);
      return newSet;
    });
    setQuestions(newQuestions);

  };
  const handleCategoryChange = (itemOID: string, event: React.ChangeEvent<{ value: unknown }>) => {
    // Find the index of the question in the questions array
    const index = questions.findIndex(q => q.OID === itemOID);
    // const newIndex=newEventData.findIndex(q => q.OID === itemOID)
    if (index === -1) {
      return; // Question not found, handle accordingly
    }
    const newQuestions = [...questions];
    const Category = event.target.value as string;

    newQuestions[index] = {
      ...newQuestions[index],
      Category: Category,
      // CodeListRef: codeList || undefined,
    };


    // Track changes in the changedItemOIDs set
    setItemOId(prev => {
      const newSet = new Set(prev);
      newSet.add(itemOID);
      return newSet;
    });
    setQuestions(newQuestions);
    //console.logg(itemOID)

  };


  //handlingItemGroupText
  const tempTextRef = useRef({}); // Store temporary text values before committing to state

  const handleItemGroupText = (event: React.ChangeEvent<HTMLInputElement>, itemGroupOid: string) => {
    const newText = event.target.value;

    // Update the temporary value immediately
    tempTextRef.current[itemGroupOid] = newText;

    // Find the index of the item group to update
    const index = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupOid);

    if (index === -1) {
      console.error(`Item group with OID ${itemGroupOid} not found.`);
      return;
    }

    // Update the item group in keyItemGroups state
    setKeyItemGroups(prevItemGroupDefs => {
      const updatedItemGroup = {
        ...prevItemGroupDefs[index],
        Description: {
          ...prevItemGroupDefs[index].Description,
          TranslatedText: {
            ...prevItemGroupDefs[index].Description.TranslatedText,
            text: newText, // Immediately update with the new text
          },
        },
      };

      return [
        ...prevItemGroupDefs.slice(0, index),
        updatedItemGroup,
        ...prevItemGroupDefs.slice(index + 1),
      ];
    });

    // Optionally update the main itemGroupDefs state
    setItemGroupDefs(prevItemGroupDefs => {
      const updatedItemGroup = {
        ...prevItemGroupDefs[index],
        Description: {
          ...prevItemGroupDefs[index].Description,
          TranslatedText: {
            ...prevItemGroupDefs[index].Description.TranslatedText,
            text: newText,
          },
        },
      };

      return [
        ...prevItemGroupDefs.slice(0, index),
        updatedItemGroup,
        ...prevItemGroupDefs.slice(index + 1),
      ];
    });
  };

  const renderComponent = (dataType: string, index: any) => {
    switch (dataType) {
      case 'integer':
        return <IntegerComponent />;
      case 'text':
        return <TextComponent />;
      case 'multilinetext':
        return <MultiLineText />;
      case 'date':
        return <DateComponent />;
      case 'datetime':
        return <DateTimeComponent />;
      case 'singleselect':
        return <SingleChoiceComponent index={index} questions={questions} setQuestions={setQuestions} codeList={codeLists} setCodeList={setCodeLists} setCodeOId={setCodeOid} />;
      case 'multiselect':
        return <MultiChoiceComponent index={index} questions={questions} setQuestions={setQuestions} codeList={codeLists} setCodeList={setCodeLists} setCodeOId={setCodeOid} />;
      case 'boolean':
        return <BooleanComponent index={index} questions={questions} setQuestions={setQuestions} codeList={codeLists} setCodeList={setCodeLists} />;
      case 'combobox':
        return <SingleChoiceComponent index={index} questions={questions} setQuestions={setQuestions} codeList={codeLists} setCodeList={setCodeLists} setCodeOId={setCodeOid} />;
      case 'multiSelectComboBox':
        return <MultiChoiceComponent index={index} questions={questions} setQuestions={setQuestions} codeList={codeLists} setCodeList={setCodeLists} setCodeOId={setCodeOid} />;
      default:
        return null;
    }
  };

  //delete question
  const handleDeleteQuestion = (itemGroupId: string, itemOID: string) => {
    // Find the index of the question to delete
    const index = questions.findIndex(q => q.OID === itemOID);
    if (index === -1) {
      return; // Question not found, handle accordingly
    }
    // Remove question from questions state
    const updatedQuestions = [...questions];
    const deletedQuestion = updatedQuestions.splice(index, 1)[0];
    setQuestions(updatedQuestions);

    // Remove associated CodeListRef from codeLists if present
    if (deletedQuestion.CodeListRef) {
      const updatedCodeLists = codeLists.filter(cl => cl.OID !== deletedQuestion.CodeListRef?.CodeListOID);
      setCodeLists(updatedCodeLists);
    }

    // Update itemGroupDefs to remove ItemOID from the specified ItemGroupDef
    const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
      if (itemGroup.OID === itemGroupId) {
        return {
          ...itemGroup,
          ItemRef: itemGroup.ItemRef.filter((itemRef) => itemRef.ItemOID !== itemOID),
        };
      }
      return itemGroup;
    });
    // Update state with the modified itemGroupDefs
    setItemGroupDefs(updatedItemGroupDefs);
  };

  const handleClick = (event, itemGroupOid, itemOId) => {
    setAnchorEl(event.currentTarget);
    setConditionalRefItemOId(itemOId); // Handle your conditional ref here
    setConditionalDefItemGroupOId(itemGroupOid || ''); // Handle your conditional ref here


  };
  const handleItemGroupClick = (event, itemGroupOid) => {
    console.log(itemGroupOid)
    setAnchorIt(event.currentTarget);
    conditionalRefItemGroupOId.current = itemGroupOid; // Handle your conditional ref here
    setConditionalDefItemGroupOId(itemGroupOid || ''); // Handle your conditional ref here


  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleItemGroupClose = () => {
    setAnchorIt(null);
  };

  // Function to handle condition expression
  // to get the itemdefs for conditional ref

  // to get the itemdefs for conditional ref
  const handleConditionalRef = (itemOId: string, type: string) => {
    ////////  //console.logg("itemOid", itemOId)
    ////////  //console.logg("itemGroupOid", conditionalDefItemGroupOId)
    setConditionalRef(itemOId); // Handle your conditional ref here
    const itemOids: string[] = []; // Array to store ItemOIDs

    const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
      if (itemGroup.OID) {
        const filteredItemRefs = itemGroup.ItemRef.filter((itemRef) => itemRef.ItemOID);
        filteredItemRefs.forEach((itemRef) => {
          ////////  //console.logg(itemRef.ItemOID); // Log the OID or use it as needed
          if (itemRef.ItemOID) {
            itemOids.push(itemRef.ItemOID);
          }
        });
        return {
          ...itemGroup,
          ItemRef: filteredItemRefs,
        };
      }
      return itemGroup;
    });


    const itemDef = questions.filter((question) => question.OID !== itemOId);
    ////////  //console.logg('Collected ItemDef:', itemDef)
    setItemDefs(itemDef);
    generateCondtionDefRef(conditionalDefItemGroupOId, itemOId, "question")
    setItemGroupDefs(updatedItemGroupDefs); // Update itemGroupDefs state with modified itemGroupDefs
    handleClose(); // Close any modal or dropdown
  };
  //to handleRepeat
  const handleToggleRepeating = (itemGroupOid: string) => {
    const updatedItemGroupDefs = itemGroupDefs.map((itemGroup) => {
      if (itemGroup.OID === itemGroupOid) {
        return {
          ...itemGroup,
          Repeating: itemGroup.Repeating === "Yes" ? "No" : "Yes"
        };
      }
      return itemGroup;
    });

    setItemGroupDefs(updatedItemGroupDefs);
  };

  //to handle drag and drop functionality 
  const handleDragEnd = (event, itemGroupId: string) => {
    const { active, over } = event;

    // Find the index of the existing item group
    const existingItemGroupIndex = itemGroupDefs.findIndex(itemGroup => itemGroup.OID === itemGroupId);
    if (existingItemGroupIndex !== -1) {
      // If ItemGroupOID exists, find the ItemRef
      const existingItemGroup = { ...itemGroupDefs[existingItemGroupIndex] };
      // Find the index of the active item in the ItemRef array
      const existingItemRefIndex = existingItemGroup.ItemRef.findIndex(itemRef => itemRef.ItemOID === active.id);
      if (existingItemRefIndex !== -1) {
        // Update the index of the moved item in the ItemRef array
        const updatedItemRefs = [...existingItemGroup.ItemRef];
        const [movedItemRef] = updatedItemRefs.splice(existingItemRefIndex, 1);
        // Determine the new index for the moved item
        let newIndex;
        if (over) {
          newIndex = updatedItemRefs.findIndex(itemRef => itemRef.ItemOID === over.id);
          if (newIndex === -1) {
            newIndex = updatedItemRefs.length;
          }
        } else {
          newIndex = updatedItemRefs.length;
        }
        // Insert the moved item at the new index
        updatedItemRefs.splice(newIndex, 0, movedItemRef);
        // Update the item group with the new ItemRef array
        const updatedItemGroup = { ...existingItemGroup, ItemRef: updatedItemRefs };
        const updatedItemGroups = [...itemGroupDefs];
        updatedItemGroups[existingItemGroupIndex] = updatedItemGroup;

        // Update the state with the new item groups
        setItemGroupDefs(updatedItemGroups);
      }
    }
  };
  const styles = {
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px', // Full viewport height
      // width: '100vw',  // Full viewport width
    },
  };
  // Utility function to move an item in an array from one index to another
  const arrayMove = (array, from, to) => {
    const newArray = [...array];
    const [movedItem] = newArray.splice(from, 1);
    newArray.splice(to, 0, movedItem);
    return newArray;
  };
  if (loading.current) {
    //   //console.logg('LOADING')
    return (
      <div style={styles.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <Typography>{loading.current}</Typography>

      <Box sx={{ width: '100%', typography: 'body1', marginLeft: '0px' }}>
        <Grid container spacing={2}>
          {/* {loading && <CircularProgress />} */}

          <Grid item xs={12} md={12} sm={12} lg={12}>
            {keyItemGroups.length === 0 && (
              <>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box> */}
                <Card className="questionBuilderCard m-2">
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    margin={7}
                  >
                    <Typography m={2}>Your Item Groups are empty, create one</Typography>
                    <Button variant="contained" onClick={handleEmptyItemGroups}>
                      Add Item Group
                    </Button>
                  </Box>
                </Card>
              </>
            )}
            {keyItemGroups.map((itemGroup, groupIndex) => {

              const existingFormIndex = formDefs.findIndex(form => form.OID === formDef.OID);

              const existingFormGroup = { ...formDefs[existingFormIndex] };

              const conditionItemGroupData = existingFormGroup.ItemGroupRef.find(
                (fi) => fi.ItemGroupOID === itemGroup.OID
              );

              //console.log("ItemGroup:", itemGroup);
              // console.log("Matching FormDef ItemGroupRefs:", conditionItemGroupData);
              //console.log(conditionItemGroupData,formDef,existingFormIndex)
              return (

                <React.Fragment key={groupIndex}>

                  <Card
                    className={`questionBuilderCard m-2 itemGroup ${selectedItemGroupCard === itemGroup.OID ? 'selectedCard' : ''}`}
                    onClick={() => handleItemGroupCardClick(itemGroup?.OID)}
                  >

                    {/* {itemGroup.Description.TranslatedText.text===''?():()} */}
                    <Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          {/* <Stack>Enter your group header name </Stack> */}
                        </Grid>
                        <Grid item xs={12} md={8} sm={12}>
                          <TextField
                            className='itemGroupHeader'
                            label="Enter your item Group Name"
                            id={`filled-size-small-${itemGroup.OID}`}
                            // variant="filled"
                            value={itemGroup.Description.TranslatedText.text}
                            size="small"
                            fullWidth
                            onChange={(e: any) => handleItemGroupText(e, itemGroup?.OID)}
                            InputProps={{
                              style: {
                                backgroundColor: 'white !important', // Input background color
                                color: 'black', // Text color
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: 'black', // Label color
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} sm={12} className='rightEnd'>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={itemGroup.Repeating === "Yes"}
                                onChange={() => handleToggleRepeating(itemGroup.OID)}
                                color="primary"
                              />
                            }
                            label={itemGroup.Repeating === "Yes" ? "Repeating" : "Not Repeating"}
                          />
                          <IconButton
                            className="deleteIcon pr-2"
                            onClick={() => handleDeleteItemGroup(itemGroup.OID)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>

                        </Grid>
                      </Grid>
                      <Divider className='mt-2' />
                    </Stack>
                    {itemGroup?.ItemRef?.map((itemRef) => {
                      const questionItemDef = questions?.filter((q) => q.OID === itemRef.ItemOID);

                      const isItemSelected = questionItemDef[0]?.OID === selectedQuestionOID;
                      const cardClassName = `questionBuilderCard itemCard ${isItemSelected ? '  borderStyle' : ''}`;
                      const isIncomplete = incompleteQuestions.includes(questionItemDef[0]?.OID);
                      const isChecked = itemRef.Mandatory === "Yes";  // Check if Mandatory is "Yes"
                      const conditionalDefData = conditionalDef?.filter((c) => c.OID === itemRef.CollectionExceptionConditionOID);
                      // const FormalExpressionCondition=conditionalDefData[0].FormalExpression;
                      //console.log(conditionalDefData.filter((q) => q.FormalExpression.text))
                      return (
                        questionItemDef.length !== 0 &&
                        // questionItemDef.map((question, index) => (
                        <Card key={questionItemDef[0]?.OID} className={cardClassName} onClick={() => handleQuestionClick(questionItemDef[0]?.OID)}>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={8} sm={12}>
                                <TextField
                                  label="Type Your Question"
                                  value={questionItemDef[0].Question.TranslatedText.text !== undefined ? questionItemDef[0]?.Question?.TranslatedText?.text : ''}
                                  id={`filled-size-small-${questionItemDef[0]?.OID}`}
                                  variant="filled"
                                  size="small"
                                  fullWidth
                                  onChange={(e) => handleQuestionChange(questionItemDef[0]?.OID, 'question', e.target.value)}
                                  error={isIncomplete}
                                  helperText={isIncomplete ? 'This field is required' : ''}
                                  InputProps={{
                                    readOnly: questionItemDef[0]?.MetaKey !== undefined,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sm={12}>
                                <FormControl sx={{ m: 1 }} size="small" fullWidth>
                                  <InputLabel id={`demo-select-small-label-${questionItemDef[0]?.OID}`}>Data Type</InputLabel>
                                  <Select
                                    labelId={`demo-select-small-label-${questionItemDef[0]?.OID}`}
                                    id={`demo-select-small-${questionItemDef[0]?.OID}`}
                                    value={questionItemDef[0]?.DataType || ''}
                                    label="Data Type"
                                    onChange={(e: any) => handleDataTypeChange(questionItemDef[0]?.OID, e)}
                                    fullWidth
                                  >
                                    <MenuItem value={questionItemDef[0]?.DataType || ''}>
                                      {questionItemDef[0]?.DataType || <em>None</em>}
                                    </MenuItem>
                                    <MenuItem value="integer">Integer</MenuItem>
                                    <MenuItem value="text">Text</MenuItem>
                                    <MenuItem value="multilinetext">Multi Line Text</MenuItem>
                                    <MenuItem value="singleselect">Single Choice</MenuItem>
                                    <MenuItem value="multiselect">Multi Choice</MenuItem>
                                    <MenuItem value="boolean">Boolean</MenuItem>
                                    {/* <MenuItem value="combobox">Combo Box</MenuItem>
                                    <MenuItem value="multiSelectComboBox">Multi select Combo Box</MenuItem> */}
                                    <MenuItem value="datetime">Date Time</MenuItem>
                                    <MenuItem value="date">Date </MenuItem>

                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* <Grid item xs={12} md={1}>
                              <Grid container justifyContent="flex-end">
                                <Grid item>
                                  <IconButton
                                    className="deleteIcon"
                                    onClick={() => handleDeleteQuestion(itemGroup.OID, questionItemDef[0]?.OID)}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid> */}
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={8} sm={12}>
                                {renderComponent(questionItemDef[0]?.DataType, questionItemDef[0]?.OID)}
                              </Grid>
                              {(questionItemDef[0]?.DataType === 'text' || questionItemDef[0]?.DataType === 'integer') && (
                                <Grid item xs={12} md={4} mt={2}>
                                  <FormControl size="small" fullWidth>
                                    <InputLabel >Measurement unit</InputLabel>
                                    <Select
                                      value={questionItemDef[0]?.MeasurementOid !== undefined ? questionItemDef[0]?.MeasurementOid : ''}  // Default to empty string if undefined
                                      label="Measurement unit"
                                      onChange={(e) => handleMeasurementChange(questionItemDef[0]?.OID || '', e.target.value)}
                                      fullWidth
                                    >
                                      {
                                        basicDefinitionData.MeasurementUnit.map((item) => {
                                          if (item !== undefined) {

                                            const translatedTextArray = Array.isArray(item.Symbol.TranslatedText)
                                              ? item.Symbol.TranslatedText
                                              : [item.Symbol.TranslatedText];

                                            const text = translatedTextArray[0]?.text || 'Default Text';  // Ensure fallback text

                                            return (
                                              <MenuItem key={item.OID} value={item.OID}>
                                                {text}
                                              </MenuItem>
                                            );
                                          }

                                        })
                                      }
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid mt={1} item xs={12} md={12} sm={12}>
                                <FormControl fullWidth size="small">
                                  <InputLabel id="category-select-label">Category</InputLabel>
                                  <Select
                                    labelId="category-select-label"
                                    id="category-select"
                                    value={
                                      category?.length > 0 && category.some((cat) => cat.name === questionItemDef[0]?.Category)
                                        ? questionItemDef[0].Category
                                        : ''  // Ensure '' only if categories are available
                                    }
                                    onChange={(e: any) => handleCategoryChange(questionItemDef[0]?.OID, e)}
                                    label="Category"
                                    disabled={category?.length === 0}  // Disable the select if no categories available
                                  >
                                    {category?.length > 0 ? (
                                      category.map((cat) => (
                                        <MenuItem key={cat?.id} value={cat?.name}>
                                          {cat.name}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem value="">
                                        <em>No Categories Available</em>
                                      </MenuItem>
                                    )}
                                  </Select>
                                </FormControl>


                              </Grid>
                              {/* <Grid item xs={12} md={4} mt={2}>   <FormControl  fullWidth size="small">
                            <InputLabel id="category-select-label">Category</InputLabel>
                            <Select
                              labelId="category-select-label"
                              id="category-select"
                              value={selectedCategory}
                              onChange={handleChange}
                              label="Category"
                            >
                              {Category.category.map((cat) => (
                                <MenuItem key={cat} value={cat}>
                                  {cat}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl></Grid> */}
                            </Grid>

                            {/* {codeList.find(cl => cl.OID === questions.find(q => q.OID === index)?.CodeListRef?.CodeListOID)?.CodeListItem?.map((option: CodeListItem, optionIndex: number) => ( */}
                            {/* {conditionalDef.find((cd=>cd.OID === itemRef.CollectionExceptionConditionOID) => { */}
                            {itemRef.CollectionExceptionConditionOID && (
                              <ConditionalDef conditionalDef={conditionalDef} setConditionalDef={setConditionalDef} itemRef={itemRef} itemGroupDefs={keyItemGroups} codeLists={codeLists} itemDefs={questions} itemGroupOID={itemGroup.OID} questionOID={questionItemDef[0]?.OID} setItemGroupDefs={setItemGroupDefs} setConditionOid={setConditionOid} />
                            )
                            }
                            {/* })} */}
                            {/* //category */}

                          </CardContent>
                          <CardActionArea>
                            <Divider />
                            <Grid item xs={12}>
                              <Box display="flex" justifyContent="end" alignItems="center">
                                {questionItemDef[0]?.MetaKey !== undefined ? (
                                  <>
                                    <IconButton className="deleteIcon pr-2">
                                      <ContentCopyIcon />
                                    </IconButton>
                                    <IconButton className="deleteIcon pr-2">
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                    {/* <Divider orientation="vertical" flexItem /> */}
                                    <>
                                      Required
                                      <Switch
                                        // Determine the checked state for the specific item
                                        checked={isChecked}
                                        // Toggle the required status for the specific item on change
                                        // onChange={() => handleToggleRequired(itemGroup.OID, itemRef.ItemOID)}
                                        color="primary"
                                      />
                                    </>
                                  </>
                                )
                                  //  :role==='admin' ? (
                                  //   <>
                                  //     <IconButton className="deleteIcon pr-2">
                                  //       <ContentCopyIcon />
                                  //     </IconButton>
                                  //     <IconButton className="deleteIcon pr-2">
                                  //       <DeleteOutlineIcon />
                                  //     </IconButton>

                                  //     <>
                                  //       Required
                                  //       <Switch
                                  //         // Determine the checked state for the specific item
                                  //         checked={isChecked}
                                  //         // Toggle the required status for the specific item on change
                                  //         // onChange={() => handleToggleRequired(itemGroup.OID, itemRef.ItemOID)}
                                  //         color="primary"
                                  //       />
                                  //     </>
                                  //   </>
                                  // ) 
                                  : (
                                    <>
                                      <IconButton
                                        className="deleteIcon pr-2"
                                        onClick={() => handleDuplicateQuestion(itemGroup.OID, questionItemDef[0]?.OID)}
                                      >
                                        <ContentCopyIcon />
                                      </IconButton>
                                      <IconButton
                                        className="deleteIcon pr-2"
                                        onClick={() => handleDeleteQuestion(itemGroup.OID, questionItemDef[0]?.OID)}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                      {/* <Divider orientation="vertical" flexItem /> */}
                                      Required
                                      <Switch
                                        // Determine the checked state for the specific item
                                        checked={isChecked}
                                        // Toggle the required status for the specific item on change
                                        onChange={() => handleToggleRequired(itemGroup.OID, itemRef.ItemOID)}
                                        color="primary"
                                      />
                                    </>
                                  )}

                                <Grid>
                                  <IconButton className="deleteIcon pr-2" onClick={(e: any) => handleClick(e, itemGroup.OID, questionItemDef[0]?.OID)}>
                                    <MoreVertIcon />
                                  </IconButton>


                                </Grid>

                              </Box>
                            </Grid>
                          </CardActionArea>
                        </Card>
                        // ))
                      );
                    })}

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {/* {menuItems.map((item, index) => ( */}
                      <MenuItem
                        onClick={() => handleConditionalRef(conditionalRefItemOId, "question")}
                      >
                        Conditional Render
                      </MenuItem>
                      {/* ))} */}
                    </Menu>
                    {/* {role === 'admin' ? (
                    <Button
                      className="mt-2"
                      onClick={() => handleAddQuestion(itemGroup.OID)}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Send Request  to add question              
                      </Button>
                  ) :
                    ( */}
                    <Button
                      className="mt-2"
                      onClick={() => handleAddQuestion(itemGroup.OID)}
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      Add Question
                    </Button>
                    <>
                    
                    <Menu
                        anchorEl={anchorIt}
                        open={Boolean(anchorIt)}
                        onClose={handleItemGroupClose}
                      >
                        {/* {menuItems.map((item, index) => ( */}
                        <MenuItem
                          onClick={() => generateCondtionDefRef(conditionalRefItemGroupOId.current, "", "ItemGroup")}
                        >
                          Conditional Render
                        </MenuItem>
                        {/* ))} */}
                      </Menu>
                      {conditionItemGroupData?.CollectionExceptionConditionOID && (
                        <ItemGroupConditionDef
                          conditionalDef={conditionalDef}
                          setConditionalDef={setConditionalDef}
                          itemGroupDefs={keyItemGroups}
                          codeLists={codeLists}
                          itemDefs={questions}
                          itemGroupOID={itemGroup.OID}
                          setItemGroupDefs={setItemGroupDefs}
                          setConditionOid={setConditionOid}
                          itemRef={conditionItemGroupData}
                          formDef={formDef}
                          formDefs={formDefs}
                          questionOID={undefined}
                          setFormDef={setFormDef}
                        />

                      )
                      }
                      <Grid 
                        container
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                      >
                        <IconButton
                          className="deleteIcon pr-2"
                          onClick={(e: any) => handleItemGroupClick(e, itemGroup?.OID)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Grid>

                    </>

                    {/* )} */}

                  </Card>

                  {/* )} */}
                </React.Fragment>
              )
            })}
            {/* <Button
              className="mt-2"
              onClick={handleSaveAll}
              fullWidth
              variant="contained"
              color="primary"
            >
              Save All
            </Button> */}
          </Grid>

          {keyItemGroups.length !== 0 && (


            <Box display="flex" ml={2} mt={2} justifyContent="flex-end">
              {/* <Grid container spacing={2}> */}

              {/* {role !== 'admin' && (  */}
              <Button variant="contained"
                onClick={handleEmptyItemGroups}>
                Add Item Group
              </Button>
              {/* )} */}

              {/* </Grid>
              <Grid item xs={12} md={9}></Grid> */}
              {/* </Grid> */}
            </Box>
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default QuestionCard;
